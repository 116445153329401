import ButtonLink from "core/components/ButtonLink";
import { Row } from "core/styles";
import styled from "styled-components";

import { getOrganizationById, getUsers } from "modules/admin/actions";
import useCurrentUser from "core/hooks/useCurrentUser";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState, useMemo, useEffect } from "react";
import { REVERSE_LOOKUP_TABLEAU_ROLES } from "core/constants";
import Typography from "core/components/Typography";
import { motion } from "framer-motion";

import Matter from "matter-js";
import "./falling.css";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-top: 125px;
  padding-left: 50px;
  padding-right: 50px;

  background-image: url(/home-arrows-normal.svg);
  background-repeat: no-repeat;
  background-size: 100vw auto;
  background-position: center bottom;

  @media only screen and (max-width: 1400px) {
    padding-top: 60px;
    background-image: url(/home-arrows-narrow.svg);
    background-size: 100vw auto;
    background-position: center bottom;
  }

  @media only screen and (min-width: 2000px) {
    padding-top: 0vh;
    /* height: 100vh; */
    background-image: url(/home-arrows-wide.svg);
  }
`;

const HomeColumns = styled.div`
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
  max-width: 1400px;
  align-items: flex-start;

  @media only screen and (max-width: 1400px) {
    max-width: 700px;
    flex-direction: column;
  }

  @media only screen and (min-width: 2000px) {
    max-width: 100%;
    margin-top: 25vh;
  }
`;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  flex-wrap: wrap;
  flex: 1 1 350px;

  @media only screen and (max-width: 1400px) {
    margin-top: 0px;
    flex: 0 1 auto;
  }

  @media only screen and (min-width: 2000px) {
    max-width: 470px;
    margin-top: 0px;
  }
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  @media only screen and (min-width: 2000px) {
    max-width: 100%;
    flex-direction: row;
  }

  /* flex: 0 1 auto; */
  /* flex-shrink: 1; */

  /* @media only screen and (min-width: 1400px) {
    padding-right: 80px;
  } */
`;

const Card = styled(Row)`
  padding-left: 38px;
  padding-right: 25px;
  min-height: 295px;
  max-width: 690px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
`;

const StyledHeaderPrimary = styled(Typography)`
  font-size: 60px;
  line-height: 60px;
  font-weight: 600;
`;

const StyledHeaderSecondary = styled(Typography)`
  font-size: 36px;
  line-height: 60px;
`;

const FallingText = ({
  text = "",
  highlightWords = [],
  highlightClass = "highlighted",
  trigger = "auto",
  delay = 0,
  backgroundColor = "transparent",
  wireframes = false,
  gravity = 1,
  mouseConstraintStiffness = 0.2,
  fontSize = "1rem",
}) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const canvasContainerRef = useRef(null);

  const [effectStarted, setEffectStarted] = useState(false);

  useEffect(() => {
    if (!textRef.current) return;
    const words = text.split(" ");
    const newHTML = words
      .map((word) => {
        const isHighlighted = highlightWords.some((hw) => word.startsWith(hw));
        return `<span class="word ${
          isHighlighted ? highlightClass : ""
        }">${word}</span>`;
      })
      .join(" ");
    textRef.current.innerHTML = newHTML;
  }, [text, highlightWords, highlightClass]);

  useEffect(() => {
    if (trigger === "auto") {
      setTimeout(() => {
        setEffectStarted(true);
      }, delay * 1000);
      return;
    }
    if (trigger === "scroll" && containerRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setEffectStarted(true);
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(containerRef.current);
      return () => observer.disconnect();
    }
  }, [trigger, delay]);

  useEffect(() => {
    if (!effectStarted) return;

    const { Engine, Render, World, Bodies, Runner, Mouse, MouseConstraint } =
      Matter;

    const containerRect = containerRef.current.getBoundingClientRect();
    const width = containerRect.width;
    const height = containerRect.height;

    if (width <= 0 || height <= 0) {
      return;
    }

    const engine = Engine.create();
    engine.world.gravity.y = gravity;

    const render = Render.create({
      element: canvasContainerRef.current,
      engine,
      options: {
        width,
        height,
        background: backgroundColor,
        wireframes,
      },
    });

    const boundaryOptions = {
      isStatic: true,
      render: { fillStyle: "transparent" },
    };
    const floor = Bodies.rectangle(
      width / 2,
      height + 25,
      width,
      50,
      boundaryOptions
    );
    const leftWall = Bodies.rectangle(
      -25,
      height / 2,
      50,
      height,
      boundaryOptions
    );
    const rightWall = Bodies.rectangle(
      width + 25,
      height / 2,
      50,
      height,
      boundaryOptions
    );
    const ceiling = Bodies.rectangle(
      width / 2,
      -25,
      width,
      50,
      boundaryOptions
    );

    const wordSpans = textRef.current.querySelectorAll(".word");
    const wordBodies = [...wordSpans].map((elem) => {
      const rect = elem.getBoundingClientRect();

      const x = rect.left - containerRect.left + rect.width / 2;
      const y = rect.top - containerRect.top + rect.height / 2;

      const body = Bodies.rectangle(x, y, rect.width, rect.height, {
        render: { fillStyle: "transparent" },
        restitution: 0.8,
        frictionAir: 0.01,
        friction: 0.2,
      });

      Matter.Body.setVelocity(body, {
        x: (Math.random() - 0.5) * 5,
        y: 0,
      });
      Matter.Body.setAngularVelocity(body, (Math.random() - 0.5) * 0.05);
      return { elem, body };
    });

    wordBodies.forEach(({ elem, body }) => {
      elem.style.position = "absolute";
      elem.style.left = `${
        body.position.x - body.bounds.max.x + body.bounds.min.x / 2
      }px`;
      elem.style.top = `${
        body.position.y - body.bounds.max.y + body.bounds.min.y / 2
      }px`;
      elem.style.transform = "none";
    });

    const mouse = Mouse.create(containerRef.current);
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse,
      constraint: {
        stiffness: mouseConstraintStiffness,
        render: { visible: false },
      },
    });
    render.mouse = mouse;

    World.add(engine.world, [
      floor,
      leftWall,
      rightWall,
      ceiling,
      mouseConstraint,
      ...wordBodies.map((wb) => wb.body),
    ]);

    const runner = Runner.create();
    Runner.run(runner, engine);
    Render.run(render);

    const updateLoop = () => {
      wordBodies.forEach(({ body, elem }) => {
        const { x, y } = body.position;
        elem.style.left = `${x}px`;
        elem.style.top = `${y}px`;
        elem.style.transform = `translate(-50%, -50%) rotate(${body.angle}rad)`;
      });
      Matter.Engine.update(engine);
      requestAnimationFrame(updateLoop);
    };
    updateLoop();

    return () => {
      Render.stop(render);
      Runner.stop(runner);
      if (render.canvas && canvasContainerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        canvasContainerRef.current.removeChild(render.canvas);
      }
      World.clear(engine.world);
      Engine.clear(engine);
    };
  }, [
    effectStarted,
    gravity,
    wireframes,
    backgroundColor,
    mouseConstraintStiffness,
  ]);

  const handleTrigger = () => {
    if (!effectStarted && (trigger === "click" || trigger === "hover")) {
      setEffectStarted(true);
    }
  };

  return (
    <div
      ref={containerRef}
      data-effect-started={effectStarted}
      className="falling-text-container"
      onClick={trigger === "click" ? handleTrigger : undefined}
      onMouseOver={trigger === "hover" ? handleTrigger : undefined}
    >
      <div
        ref={textRef}
        className="falling-text-target"
        style={{
          fontSize: fontSize,
          fontWeight: fontSize === "4rem" ? 600 : 400,
        }}
      />
      <div ref={canvasContainerRef} className="falling-text-canvas" />
    </div>
  );
};

const Home = () => {
  const {
    currentUser: { organizationId, email },
  } = useCurrentUser();

  const fetchOrgQueryCall = useAuthenticatedCall((req) =>
    getOrganizationById({ ...req, organizationId })
  );
  const { data: organization } = useQuery({
    queryKey: ["organizations", organizationId],
    queryFn: fetchOrgQueryCall,
  });

  const fetchUsersQueryCall = useAuthenticatedCall(getUsers);
  const { data: users } = useQuery({
    queryKey: ["users", organizationId, { email }],
    queryFn: fetchUsersQueryCall,
  });

  const user = useMemo(() => users?.[0], [users]);
  const hasBiPortalLicense = useMemo(
    () =>
      user?.tableauConfig?.tableauLicense &&
      Object.values(REVERSE_LOOKUP_TABLEAU_ROLES).includes(
        user?.tableauConfig?.tableauLicense
      ),
    [user]
  );

  const biPortalUrl = useMemo(
    () => organization?.onboarding?.bi?.portal_url,
    [organization]
  );

  const biPortal = useMemo(
    () =>
      user && organization // user and org has loaded
        ? hasBiPortalLicense && biPortalUrl
          ? {
              // valid bi portal link and user license
              disabled: false,
              errorMessage: null,
            }
          : {
              // invalid
              disabled: true,
              errorMessage: !hasBiPortalLicense
                ? "You do not have a BI Portal license assigned." // no BI licence for user
                : "The BI Portal is not set up for your organization.", // no BI portal link for org
            }
        : {
            // user and/or org not loaded yet, disable button
            disabled: true,
            errorMessage: null,
          },
    [user, organization, hasBiPortalLicense, biPortalUrl]
  );

  useEffect(() => {
    window.history.pushState({ from: "Home" }, "");
  }, []);

  return (
    <Container>
      <HomeColumns>
        <TitleColumn
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              top: "0px",
              left: "0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FallingText
              text="We love you..."
              trigger="auto"
              backgroundColor="transparent"
              wireframes={false}
              gravity={0.56}
              fontSize="2rem"
              mouseConstraintStiffness={0.9}
              delay={2}
            />

            <FallingText
              text="Happy April Fool's Day!"
              trigger="auto"
              backgroundColor="transparent"
              wireframes={false}
              gravity={0.56}
              fontSize="4rem"
              mouseConstraintStiffness={0.9}
              delay={3}
            />
          </div>
        </TitleColumn>

        <CardColumn
          style={{
            marginTop: "150px",
          }}
        >
          <motion.div
            animate={{ y: "55vh", rotate: 530 }}
            transition={{
              duration: 1,
              delay: 4,
              rotate: { duration: 3, delay: 4 },
            }}
          >
            <Card>
              <img
                src="/bi-portal-graphic.png"
                alt="BI Portal"
                style={{ height: "240px", width: "198.19px" }}
              />
              <div>
                <Typography variant="h1">BI Portal</Typography>
                <Typography variant="p">
                  Our BI Portal provides ready-to-use dashboards for Market,
                  Provider, and Population Intelligence. You can access a
                  variety of dashboards across Population, Market, and Provider
                  Intelligence, based on your organization’s subscription.
                </Typography>

                <Row style={{ gap: "5px" }}>
                  <ButtonLink
                    as="a"
                    width="fit-content"
                    disabled={biPortal.disabled}
                    href={biPortal.disabled ? null : biPortalUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Launch My Portal
                  </ButtonLink>
                  {biPortal.errorMessage ? (
                    <Typography variant="sub-text">
                      {biPortal.errorMessage}
                    </Typography>
                  ) : null}
                </Row>
              </div>
            </Card>
          </motion.div>

          <motion.div
            animate={{ y: "35vh", rotate: -520 }}
            transition={{
              duration: 1,
              delay: 5,
              rotate: { duration: 3, delay: 5 },
            }}
          >
            <Card>
              <img
                src="/support-graphic.png"
                alt="Support"
                style={{ height: "228px", width: "189px" }}
              />
              <div>
                <Typography variant="h1">Success Portal</Typography>
                <Typography variant="p">
                  Our Support Portal provides valuable end-user documentation,
                  including data dictionaries, product overviews, training
                  content, support articles, and release notes. You can also use
                  the Support Portal to submit questions or issues.
                </Typography>
                <ButtonLink
                  as="a"
                  width="fit-content"
                  href="https://success.populi.ai/hc/en-us"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Support
                </ButtonLink>
              </div>
            </Card>
          </motion.div>
        </CardColumn>
      </HomeColumns>
    </Container>
  );
};

export default Home;
